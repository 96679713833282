// ordered alphabetically
const accessLevelsOptionsPath = '/api/news/access_levels';
const accountsPath = '/api/accounts';
const adminAppleNewsPath = '/api/admin/apple_news/articles';
const adminConsultantsPath = '/api/admin/consultants';
const adminDownloadablesPath = '/api/admin/downloadables';
const adminEventsPath = '/api/admin/events';
const adminFundingInfoPath = '/api/funding_infos';
const adminFundingProjectsPath = '/api/funding_projects';
const alertsPath = '/api/alerts';
const analyticsActivitiesPath = '/api/public/analytics/activities';
const analyticsJobApplicationsPath = '/api/analytics/job_applications';
const assetsPath = '/api/assets';
const autocompleteArticlesPath = '/api/public/autocomplete/articles';
const autocompleteAuthorsPath = '/api/public/autocomplete/authors';
const autocompleteCampaignsPath = '/api/public/autocomplete/campaigns';
const autocompleteCompaniesPath = '/api/public/autocomplete/companies';
const autocompleteConsultantsPath = '/api/public/autocomplete/consultants';
const autocompleteContentPartnersPath = '/api/public/autocomplete/content_partners';
const autocompleteCurrenciesPath = '/api/public/currencies';
const autocompleteDonorsPath = '/api/public/autocomplete/donors';
const autocompleteEditorsPath = '/api/public/autocomplete/contents/editors';
const autocompleteJobsEmployerCompaniesPath = '/api/public/autocomplete/jobs/employer_companies';
const autocompleteLanguagesPath = '/api/public/autocomplete/languages';
const autocompleteNewsTopicsPath = '/api/classification/frameworks/news_topics';
const autocompletePlacesPath = '/api/public/autocomplete/places';
const bookmarkedSearchesPath = '/api/public/current_user/bookmarked_searches';
const bundlesPath = '/api/bundles';
const checkEmailPath = '/apps/front_end/api/form_inputs/check_email';
const careerLevelOptions = '/api/career_levels';
const companiesAdminSearchPath = '/api/companies/admin_search';
const companiesPath = '/api/companies';
const companiesSearchPath = '/api/public/search/companies';
const consultantInterestsPath = '/api/consultant_interests';
const consultantsPath = '/api/consultants';
const consultantsSearch = '/api/public/search/consultants';
const contentsPath = '/api/news/contents';
const contractLengthsPath = '/api/contract_lengths';
const contractsCsvPath = '/api/contracts/csv';
const curatedListPath = '/api/curated_candidate_lists';
const curatedListAdminPath = '/api/admin/curated_candidate_lists';
const currentUserCreditsPath = '/api/public/current_user/credits';
const currentUserPath = '/api/public/current_user';
const currentUserSessionAuthPath = '/api/current_user';
const currentUserStatsPath = '/api/public/current_user/stats';
const currentUserPrivilegesPath = '/api/public/current_user/privileges';
const developmentDataPath = '/api/public/current_user/development_data';
const editableTagsPath = '/api/public/current_user/tags/editable';
const emailSettingsPath = '/api/email_settings';
const eventsPath = '/api/events';
const favoritesPath = '/api/public/current_user/favorites';
const jobApplicationCountPath = '/api/public/jobs/applications_count';
const jobSearchPath = '/api/public/search/jobs';
const jobsPath = '/api/jobs';
const jobViewsCountPath = '/api/public/analytics/views/jobs/ranking';
const moreLikeThisPath = '/api/elasticsearch/more_like_this';
const newMessagePath = '/messages/new';
const newsCategoriesPath = '/api/news/categories';
const newsGoogleDocsPath = '/api/news/google/docs';
const newslabArticlePath = '/api/news/articles';
const newslabContentPartnerPath = '/api/news/content_partners';
const newslabNewsletterPath = '/api/news/newsletters';
const newslettersPath = '/api/public/current_user/newsletters';
const newsSearchPath = '/api/public/search/articles';
const notesPath = '/api/public/current_user/notes';
const organizationsPath = '/api/organizations';
const packagesPath = '/api/packages';
const paymentMethodsPath = '/api/public/current_user/account/payment_methods';
const paymentPagesPath = '/api/payment_pages';
const paymentPath = '/api/public/current_user/payment_pages';
const permissionsPath = '/api/permissions';
const privilegesPath = '/api/privileges';
const recruitmentEmployerCompaniesPath = '/api/recruitment/employer_companies';
const recruitmentJobsPath = '/api/recruitment/jobs';
const recruitmentJobPreferencesPath = '/api/recruitment/current_user/job_posting_preference';
const recruitmentJobStages = '/api/recruitment/job_stages';
const registrationRequestsPath = '/api/registration_requests';
const requestCvPath = '/api/public/current_user/request_cv_recipients';
const resumesPath = '/api/public/current_user/resumes';
const seatsPath = '/api/seats';
const settingsPath = '/api/public/current_user/settings';
const sharingTokens = '/api/sharing_tokens';
const statusesOptionsPath = '/api/news/contents/statuses';
const storyTypesOptionsPath = '/api/news/story_types';
const subscriptionsPath = '/api/subscriptions';
const taggingsPath = '/api/public/current_user/taggings';
const tagsPath = '/api/public/current_user/tags';
const topicsClassificationPath = '/api/classification/news_topics/classify';
const usersPath = '/api/users';

export {
  accessLevelsOptionsPath,
  accountsPath,
  adminAppleNewsPath,
  adminConsultantsPath,
  adminDownloadablesPath,
  adminEventsPath,
  adminFundingInfoPath,
  adminFundingProjectsPath,
  alertsPath,
  analyticsActivitiesPath,
  analyticsJobApplicationsPath,
  assetsPath,
  autocompleteArticlesPath,
  autocompleteAuthorsPath,
  autocompleteCampaignsPath,
  autocompleteCompaniesPath,
  autocompleteConsultantsPath,
  autocompleteContentPartnersPath,
  autocompleteCurrenciesPath,
  autocompleteDonorsPath,
  autocompleteEditorsPath,
  autocompleteJobsEmployerCompaniesPath,
  autocompleteLanguagesPath,
  autocompleteNewsTopicsPath,
  autocompletePlacesPath,
  bookmarkedSearchesPath,
  bundlesPath,
  checkEmailPath,
  careerLevelOptions,
  companiesAdminSearchPath,
  companiesPath,
  companiesSearchPath,
  consultantInterestsPath,
  consultantsPath,
  consultantsSearch,
  contentsPath,
  contractLengthsPath,
  contractsCsvPath,
  curatedListPath,
  curatedListAdminPath,
  currentUserCreditsPath,
  currentUserPath,
  currentUserSessionAuthPath,
  currentUserStatsPath,
  currentUserPrivilegesPath,
  developmentDataPath,
  editableTagsPath,
  emailSettingsPath,
  eventsPath,
  favoritesPath,
  jobApplicationCountPath,
  jobSearchPath,
  jobsPath,
  jobViewsCountPath,
  moreLikeThisPath,
  newMessagePath,
  newsCategoriesPath,
  newsGoogleDocsPath,
  newslabArticlePath,
  newslabContentPartnerPath,
  newslabNewsletterPath,
  newslettersPath,
  newsSearchPath,
  notesPath,
  organizationsPath,
  packagesPath,
  paymentMethodsPath,
  paymentPagesPath,
  paymentPath,
  permissionsPath,
  privilegesPath,
  recruitmentEmployerCompaniesPath,
  recruitmentJobsPath,
  recruitmentJobPreferencesPath,
  recruitmentJobStages,
  registrationRequestsPath,
  requestCvPath,
  resumesPath,
  seatsPath,
  settingsPath,
  sharingTokens,
  statusesOptionsPath,
  storyTypesOptionsPath,
  subscriptionsPath,
  taggingsPath,
  tagsPath,
  topicsClassificationPath,
  usersPath,
};
